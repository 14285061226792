.review-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1em;

  .ant-progress-text {
    text-align: center;
  }
}

.comment {
  border: 1px solid #efefef;
  background: #fff;
  padding: 1em;
  border-radius: 5px;
  margin-bottom: 1em;
  .name {
    font-weight: bold;
  }

  .date {
    font-size: smaller;
    color: #999;
  }

  .content {
    margin-top: 1em;
  }

  p {
    margin-bottom: 0;
  }
}

.all-review-modal {
  width: 100vw !important;

  .ant-modal-body {
    height: calc(100vh - 90px);
    overflow-y: scroll;

    .review-container {
      position: sticky;
      top: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .comment-box {
    margin-top: 2em;
  }

  .ant-rate {
    display: flex;
  }
}
