.appointment-page {
  background-color: #f2f2f7;
  min-height: 100vh;

  .container,
  section {
    background-color: #f2f2f7;
  }

  .container {
    min-height: 100vh;
  }

  .appointment-list {
    max-height: 350px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .appointment-item {
    display: flex;
    align-items: center;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    border-radius: 10px;
    transition: 0.3s;
    cursor: pointer;

    .info {
      p {
        margin-bottom: 0;
      }

      .date {
        color: #999;
        font-size: 13px;
      }

      .merchant-name {
        font-weight: 600;
        // text-transform: ;
      }

      .id {
        font-size: 13px;
      }
    }
    .avatar {
      margin-right: 15px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: contain;
        background-color: #fff;
      }
    }

    &:hover,
    &.active {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      background-color: #fff;
    }
  }
}

.appointment-detail {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  position: sticky;
  top: 4em;
  background-color: #fff;

  .badge {
    background: #d93025;
    border-radius: 20px;
    font-size: 12px;
    width: fit-content;
    padding: 5px 10px;
    margin-bottom: 2em;
    font-weight: 700;
  }

  .header {
    background-color: #101928;
    padding: 2em;
    color: #fff;
    padding-bottom: 3em;
    border-radius: 20px 20px 0 0;

    h1 {
      color: #fff;
    }

    .ant-btn-primary {
      background-color: #f2f2f7 !important;
      color: #000;
      border-radius: 3px;

      &:hover {
        background-color: #4f5b6e !important;
        border-color: #4f5b6e;
        color: white;
      }
    }

    .ant-btn.ant-btn-primary.ant-btn-dangerous {
      border-color: #d44c4c !important;
      background-color: #d44c4c !important;
      color: #fff;

      &:hover {
        border-color: #b33c3c !important;
        background-color: #b33c3c !important;
      }
    }
  }
  .avatar {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #fff;
  }

  .info {
    h1 {
      font-size: 18px;
    }

    h1,
    p {
      margin-bottom: 0;
    }

    p {
      color: #999;
    }
  }
  .content {
    background: linear-gradient(#101928 70px, #fff 0);

    border-radius: 0 0 20px 20px;
    padding: 0 40px 40px;

    .content-detail {
      background-color: #f2f2f7;
      border-radius: 20px;

      .service {
        padding: 1em;
        border-bottom: 1px solid #dcdce3;

        .time {
          color: #878c93;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .total {
    padding: 1em;
  }
}

.appointment-detail.empty {
  height: 300px !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment-modal {
  border-radius: 20px;
  overflow: hidden;
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    color: #000;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .ant-modal-close {
    top: 10px;
    right: 10px;
  }

  .appointment-detail .content {
    padding: 0 20px 20px;
    border-radius: 0;
  }
}

@media only screen and (max-width: 850px) {
  .appointment-page {
    .container {
      min-height: unset;
    }
    .header h1 {
      font-size: 25px;
    }

    .appointment-list {
      display: block;
      max-height: 500px;
    }

    .appointment-detail {
      .header {
        font-size: 25px;
      }

      display: none;
    }
  }

  .appointment-public-page {
    .appointment-detail {
      display: unset !important;
    }
    .header {
      padding: 28px;
    }

    .service,
    .total {
      font-size: 14px;
    }

    .content {
      padding: 20px;
    }

    .info {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .appointment-page {
    .appointment-detail {
      .header {
        font-size: 25px;
      }

      display: none;
    }

    .merchant-name {
      word-wrap: break-word;
      white-space: normal;
    }
  }

  .appointment-public-page {
    .appointment-detail {
      display: flex;
    }
  }
}
