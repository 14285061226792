.merchant-info {
  background: #f2f2f7;
  height: 100%;
  padding: 2em;

  .name {
    font-size: 40px;
    font-weight: 700;
    overflow-wrap: break-word;
  }

  .address {
    color: #999;
  }

  .rating {
    font-weight: 700;
    .starts {
      color: #fac531;
    }

    .sub-title {
      font-weight: 400;
      color: #999;
    }
  }
}
.social {
  margin-bottom: 1em;

  a {
    color: #000;
  }
}

.merchant-detail-page {
  padding: 0 6em;

  .banner {
    margin-left: -6em !important;
    margin-right: -6em !important;
  }
}

@media only screen and (max-width: 850px) {
  .merchant-detail-page.container {
    padding-top: 0 !important;

    .banner {
      margin-left: -1em !important;
      margin-right: -1em !important;
    }
  }

  .banner {
    flex-direction: column-reverse;
    padding: 0;
    margin-left: -1em !important;
    margin-right: -1em !important;

    .ant-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .location {
    margin-top: 1em;

    .address {
      margin-bottom: 0;
    }
  }

  .merchant-info {
    height: fit-content;

    .name {
      font-size: 25px;
    }

    .address {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    flex-direction: column-reverse;
  }

  .merchant-contact {
    .btn-group {
      display: block;

      button {
        width: 100%;
        margin-bottom: 1em;
      }
    }
  }
}
