@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

.ant-form-item-label label {
  font-weight: 500;
}

html,
body {
  font-family: "Poppins", sans-serif !important;
}

b {
  font-weight: 600 !important;
}

.ant-breadcrumb a {
  color: #e3e3e373 !important;
}

.ant-breadcrumb-separator {
  color: #e3e3e373 !important ;
}

.ant-breadcrumb > span:last-child {
  color: #fff !important;
}

// .ant-modal-content {
.ant-btn,
input,
.ant-select-selector.custom,
.custome-select .ant-select-selector {
  height: 40px !important;
  border-radius: 0.325rem !important;
}

.custome-select .ant-select-selector {
  padding: 5px 10px !important;
}
.ant-select-selector.custom {
  padding-top: 5px !important;
}

.ant-btn-primary {
  color: #fff;
  background-color: #101928 !important;
  border-color: #101928 !important;
  -webkit-transition: all 0.3s;
  font-weight: 700;
  font-size: 15px;
  padding: 0 15px;

  transition: all 0.3s;

  &:hover {
    background-color: #4f5b6e !important;
    border-color: #4f5b6e;
    color: white;
  }
}

.ant-btn.ant-btn-primary.ant-btn-dangerous {
  border-color: #ff4d4f !important;
  background: #ff4d4f !important;
}
