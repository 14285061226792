.voucher {
  height: 100%;
  position: relative;
  width: 100%;
  min-width: 300px;
  margin-bottom: 20px;
  padding: 0 20px;
  color: #fff !important;
  border-radius: 10px;
  min-height: 200px;
  cursor: pointer;
  overflow: hidden;

  .kind-tag {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    border-radius: 0 0 10px;
    padding: 0.5em 1em;
    z-index: 0;
  }

  .discount-tag {
    background-color: rgb(255 0 0 / 70%);
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 0 0 0 10px;
    padding: 0.5em 1em;
    z-index: 0;
  }

  .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
  }

  &:before {
    background: #fff;
    border-radius: 50%;

    content: "";
    display: block;
    height: 30px;
    left: -15px;
    position: absolute;
    top: 50%;
    width: 30px;
    transform: translateY(-50%);
  }

  &:after {
    background: #fff;
    border-radius: 50%;
    content: "";
    height: 30px;
    right: -15px;
    position: absolute;
    top: 50%;
    width: 30px;
    transform: translateY(-50%);
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0.5em;
    word-break: break-word;
    white-space: normal;
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tag {
    margin-right: 5px;
  }

  .code {
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 50px;
    text-overflow: ellipsis;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    height: 50px;
  }

  .used,
  .tag {
    // right: 10px;
    // top: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .progess {
    background-color: #2ac41a;
  }

  .expired {
    background-color: #faad14;
  }

  .voucher-info {
    padding: 0 20px;
    // font-weight: 00;
    text-align: left;
    font-size: 16px;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
}

.birthday {
  background: linear-gradient(-45deg, #be4af4 0%, #6c4bf6 100%) !important;
}

.reminder {
  background: linear-gradient(
    9deg,
    rgba(192, 36, 37, 1) 0%,
    rgba(240, 203, 53, 1) 100%
  );
}

.normal {
  background: linear-gradient(-45deg, #00a69c 0%, #00a36d 100%);
}

.reward {
  background: linear-gradient(-45deg, #00a69c 0%, #00a36d 100%);
}
