img {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

.filter-container {
  margin-bottom: 25px;
  .filter-item {
    label {
      font-weight: 500;
      font-size: 15px;
    }

    &.btn {
      button {
        position: relative;
        top: 12px;
      }
    }
  }
}

.no-link-style {
  color: unset;

  &:hover {
    color: unset;
  }
}

.section {
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  padding: 2em;
  margin-bottom: 1em;
}
