.category-list {
  .service-item {
    padding: 1em;
    cursor: pointer;
    transition: 0.4s;
    border-radius: 10px;

    &:hover {
      background-color: #f2f2f7;
    }
  }
  .name,
  .price {
    // font-weight: 700;
    font-size: 18px;
  }

  .time {
    font-size: 16px;
    color: #666;
  }

  .option-list {
    padding-left: 4em;
  }
}
