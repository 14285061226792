.login-page {
  min-height: 100vh;
  background: #f2f2f7;
  display: flex;
  justify-content: center;
  font-size: 16px;
  padding: 50px 0;

  .ant-input-affix-wrapper {
    padding: 0 11px;
  }

  .login-container {
    width: 450px;
    box-shadow: rgb(16 25 40 / 8%) 0px 4px 8px 0px;
    padding: 24px 40px;
    background-color: #fff;
    border-radius: 5px;

    .ant-upload-picture-card-wrapper {
      display: flex;
      justify-content: center;

      .ant-upload,
      img {
        border-radius: 50%;
      }
    }
  }

  .login-header {
    width: 100%;
    display: flex;
    position: relative;
    height: 80px;

    .logo {
      width: 60px;
      height: 60px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      margin: 0;
    }
  }

  .profile-header {
    display: flex;
    align-items: center;
    height: unset;

    h1 {
      margin-bottom: 0;
    }
  }

  button {
    height: 50px !important;
    font-weight: 700;
  }

  .btn-social {
    display: flex;
    width: 100%;
    background-color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    border: 1px solid #efefef;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 1em;
    cursor: pointer;

    .img-box {
      width: 50px;
      padding: 5px;
      height: 50px;
      border-right: 1px solid #efefef;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .btn-label {
      text-align: center;
      width: 100%;
      margin-bottom: 0;
      font-weight: 700;
    }
  }

  .login-footer {
    margin-top: 25px;

    p {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .login-page {
    display: block !important;
    padding-top: 20px;

    .profile-header {
      display: flex;
      align-items: center;
      height: unset;
      margin-bottom: 10px;
    }

    .login-container {
      width: 100% !important;
    }

    .btn-back {
      font-size: 25px;
    }
  }
}
