.ant-carousel,
.slick-track,
.slick-list {
  height: 100%;
  // max-height: 500px;
}

.carousel-component {
  .slick-slide > div {
    height: 100%;
  }
  height: 100%;
  // border: 1px solid #000;
  .item {
    width: 100%;
    height: 100%;
    background-color: aquamarine;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    float: left;
  }
}

@media only screen and (max-width: 600px) {
  .carousel-component {
    .item {
      aspect-ratio: 16/9;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
