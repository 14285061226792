.service-booking {
  margin-top: 2em;
  padding-bottom: 5em !important;

  .swiper-button-next,
  .swiper-button-prev {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #efefef;
    top: 37%;

    &::after {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .select-service-box {
    margin: 0 0 2em;
  }

  .service {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #d7d9d9;
    cursor: pointer;
    padding: 1em 2em;
    text-align: center;
    font-size: 20px;
    min-height: 90px;
    font-weight: 500;
  }

  .service-cate {
    padding: 1em;
  }

  .service-selected {
    background: linear-gradient(133.2deg, #5eddb4 0%, #60c9f6 100%);
    border-color: transparent;
    color: #fff;
  }

  .technician-select-box {
    margin: 2em 0;

    .technician-item {
      cursor: pointer;
      margin-bottom: 2em;
    }

    .ant-avatar {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      margin: auto;
    }

    .avatar-selected {
      position: relative;
    }

    .avatar-selected::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(133.2deg, #5eddb4 0%, #60c9f6 100%);
      opacity: 0.6;
    }
  }

  .selecte-time-box {
    .date-selector {
      position: relative;
      margin-top: 2em;
      .btn-slider {
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
      }
      .next {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(101%) translateY(-20%);
      }

      .pre {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateX(-100%) translateY(-20%);
      }
    }
    .date-container {
      width: 100%;
      max-width: none;
      overflow-x: scroll;
      -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
      white-space: nowrap;
      display: flex;
      scroll-behavior: smooth;
      position: relative;
      padding-top: 5px;
      // overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        opacity: 0;
      }

      .next {
        position: absolute;
        right: 0;
        top: 50%;
      }
    }

    .date {
      cursor: pointer;
      display: inline-flex;
      height: 100px;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 500;
      scroll-snap-align: start;
      border: 2px solid #d7d9d9;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      text-align: center;
      flex-direction: column;

      flex-grow: 0; /* do not grow   - initial value: 0 */
      flex-shrink: 0; /* do not shrink - initial value: 1 */
      flex-basis: 100px; /* width/height  - initial value: auto */

      p {
        margin-bottom: 0;
      }
    }

    .date-selected,
    .time-selected {
      background: linear-gradient(133.2deg, #5eddb4 0%, #60c9f6 100%);
      border: none;
      color: #fff;

      p {
        font-weight: 600 !important;
      }
    }

    .disable {
      filter: grayscale(1);
      cursor: not-allowed !important;
      background: repeating-linear-gradient(
        45deg,
        #eef0f2,
        #eef0f2 9px,
        #cfd2d6 0,
        #cfd2d6 10px
      ) !important;
    }

    .time-container {
      margin-top: 2em;
      .time {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        cursor: pointer;

        border: 2px solid #d7d9d9;
        height: 100px;
        border-radius: 20px;
        margin-bottom: 1em;
      }
    }
  }

  .custom-btn {
    height: 60px !important;
    border: none;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    min-width: 200px;
    background: linear-gradient(133.2deg, #5eddb4 0%, #60c9f6 100%);
  }

  .custom-btn.outline {
    border: 2px solid #60c9f6;
    background: #fff;
    color: #000;
  }

  .steps-action {
    text-align: center;
    margin-bottom: 2em;

    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0 !important;
    background: #fff;
    border-top: 1px solid #efefef;
    width: 100%;
    padding: 10px 0;
  }
}

@media only screen and (max-width: 850px) {
  .btn-slider {
    display: none;
  }

  .date-container {
    // overflow: scroll !important;
    &::-webkit-scrollbar {
      opacity: unset;
    }
  }
}

@media only screen and (max-width: 600px) {
  .service {
    margin-bottom: 1em;
  }

  .steps-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-steps {
    flex-direction: row;
    transform: translateX(4%);

    .ant-steps-item-active {
      .ant-steps-item-content {
        display: block !important;
      }
    }

    .ant-steps-item-content {
      display: none !important;
    }
  }

  .final-btn {
    flex-direction: column;
    .ant-space-item,
    button {
      width: 100%;
    }
  }

  .custom-btn {
    min-width: 150px !important;
  }
}
