.rate-container {
  padding: 2em 0;
  border-radius: 10px;
  text-align: center;

  .ant-rate {
    display: unset;
  }
}

.vote-social {
  margin-top: 2em;
  .google-btn {
    background-color: #d34836 !important;
    color: #fff !important;
    border: none;
    font-weight: 700;
    font-size: 20px;
    height: 50px !important;
  }

  .fb-btn {
    background-color: #3b5998 !important;
    color: #fff !important;
    border: none;
    font-weight: 700;
    font-size: 20px;
    height: 50px !important;
  }
}

@media only screen and (max-width: 851px) {
  .public-rate-page {
    .appointment-detail {
      display: unset;
    }
  }
}
