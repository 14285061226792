.technician-item {
  margin: 0 1em;
  text-align: center;

  .ant-avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
    font-size: 30px;
    line-height: 80px;
  }
  .name {
    font-weight: 700;
  }

  .selected-overplay {
    display: none;
  }

  .selected-overplay.show {
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    // margin-top: 50%;

    color: #fff;
    font-size: 50px;
  }
}

.technician-list {
  position: relative;

  .btn-slider {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(101%) translateY(-20%);
  }

  .pre {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-100%) translateY(-20%);
  }
}

.technician-container {
  width: 100%;
  max-width: none;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  white-space: nowrap;
  display: flex;
  scroll-behavior: smooth;
  position: relative;
  padding-top: 40px;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .wrap-technician-item {
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
  }
}
