.appointment-card {
  border: 1px solid #d5d7da;
  border-radius: 10px;
  padding: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  .total {
    padding-top: 0.5em;
    margin-top: auto;
    border-top: 1px solid #d5d7da;
  }

  .time {
    color: #999;
  }
  .service {
    border-bottom: 1px dashed #d5d7da;
  }

  .last-child {
    border-bottom: none;
  }
}

@media only screen and (max-width: 850px) {
  .appointment-list {
    width: 100%;
    flex-wrap: nowrap;
    max-width: none;
    overflow-x: scroll;
    -ms-scroll-snap-type: x proximity;
    scroll-snap-type: x proximity;
    white-space: nowrap;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    padding-top: 5px;
    scroll-snap-align: start;
    // overflow: -moz-scrollbars-none;
    // &::-webkit-scrollbar {
    //   width: 0 !important;
    // }
  }
}
