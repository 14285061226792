.ant-layout-header {
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .mobile-menu {
    display: none !important;
  }
}
.logo {
  float: left;

  img {
    height: 40px;
  }
}

.ant-layout-content {
  background-color: #fff;
}
.site-layout .site-layout-background {
  background: #fff;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  font-size: 18px;
}

.PhoneInput {
  border: 1px solid #d9d9d9;
  padding-left: 10px;

  input {
    border: none;
    padding-left: 10px;
  }

  input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px !important;
    outline: 0;
  }
}

.uppercase {
  text-transform: uppercase;
}

.search-mobile-btn,
.mobile-menu {
  display: none;
}

span.anticon.anticon-menu.ant-dropdown-trigger.mobile-menu {
  display: none;
}

footer {
  background: #212121;

  .socials-icon {
    display: flex;
    justify-content: center;

    margin-top: 1em;
    .ant-btn-text {
      color: #fff;
    }
  }
  a {
    display: block;
  }

  .container {
    background: #212121;
  }

  h2,
  a {
    color: #fff;
  }

  h2 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .container {
    padding: 0 2em;
  }
}

@media only screen and (max-width: 850px) {
  .container {
    padding: 1em !important;
  }

  span.anticon.anticon-menu.ant-dropdown-trigger.mobile-menu {
    display: block !important;
    font-size: 25px;
    margin-left: 15px;
  }

  .dropdown-mobile .ant-dropdown-menu-item {
    font-size: 16px;
    padding: 0.5em 2em;
  }

  .desktop-menu {
    display: none;
  }

  .mobile-slide-container {
    width: 100%;
    flex-wrap: nowrap;
    max-width: none;
    overflow-x: scroll;
    -ms-scroll-snap-type: x proximity;
    scroll-snap-type: x proximity;
    white-space: nowrap;
    display: flex;
    scroll-behavior: smooth;
    position: relative;
    padding-top: 5px;
    scroll-snap-align: start;
    padding-bottom: 1em;
    // overflow: -moz-scrollbars-none;
    // &::-webkit-scrollbar {
    //   width: 0 !important;
    // }
  }

  footer {
    .container {
      padding: 2em !important;
      .ant-col {
        margin-bottom: 2em;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .ant-layout-header {
    padding: 0 20px;
  }
  .search-mobile-btn {
    display: inline-block;
    margin-right: 10px;
    background-color: #fff;

    .anticon-search {
      font-size: 25px;
    }
  }
}
